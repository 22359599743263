(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";
$(document).ready(function () {
    $('body').prepend('<div class="custom-top-scroll"></div>');
    $(window).on('scroll', function () {
        var windowHeight = ($('.s-blog-case__case-wrap').height());
        var k = windowHeight/100;
        var scrollFromTop = $(document).scrollTop();
        var procScroll = scrollFromTop/k;
        $('.custom-top-scroll').css('width', procScroll + '%');
    });
});
},{}]},{},[1]);
